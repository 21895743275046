<template>
  <div class="terms-conditions">
    <div v-if="currentTermsText" class="terms-content">
      <!-- Customer specific header if available -->
      <div v-if="currentTermsText.headerImage" class="customer-header">
        <img 
          :src="currentTermsText.headerImage"
          :alt="currentTermsText.customer + ' Header'"
          class="w-100 "
        />
      </div>
      
      <!-- Terms content -->
      <div class="terms-container">
        <div class="terms-header">
          <h1 class="title">{{ currentTermsText.title }}</h1>
          <p class="last-update">
            Última actualización: {{ currentTermsText.lastUpdate }}
          </p>
        </div>
        
        <!-- Render HTML content safely -->
        <div v-html="currentTermsText.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { termsTexts } from './TermsTextVersions'

export default {
  name: 'TermsConditions',
  data() {
    return {
      currentTermsText: null
    }
  },
  created() {
    const customer = this.$route.params.communityId?.toLowerCase() || 'default'
    this.currentTermsText = termsTexts[customer] || termsTexts.default
  }
}
</script>

<style lang="scss" scoped>
.terms-conditions {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  .customer-header {
    margin-bottom: 2rem;
    
    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  .terms-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .terms-header {
      margin-bottom: 2rem;

      .title {
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .last-update {
        color: #666;
        font-size: 0.9rem;
      }
    }
  }
}
</style>
